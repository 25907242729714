import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import Testimonial from "../../components/testimonial";
import { PartnersGrid } from "../../components/partnersGrid";
import { ServicesTeaser } from "../../components/servicesTeaser";

import doodle_path from "../../assets/images/doodle-path.svg";
import doodle_directions from "../../assets/images/doodle-directions.svg";
import doodle_growIdeas from "../../assets/images/doodle-grow-ideas.svg";
import eppendorf from "../../assets/images/logos/svg/eppendorf.svg";
import dak_gesundheit from "../../assets/images/logos/svg/dak-gesundheit.svg";
import cosmos from "../../assets/images/logos/svg/cosmos.svg";
import tk from "../../assets/images/logos/svg/tk.svg";
import swp from "../../assets/images/logos/svg/swp.svg";
import generali from "../../assets/images/logos/svg/generali.svg";
import hansemerkur_green from "../../assets/images/logos/svg/hansemerkur_green.svg";
import bgw_green from "../../assets/images/logos/svg/bgw_green.svg";
import haspa_green from "../../assets/images/logos/svg/haspa_green.svg";

const Services = () => {
  const images = useStaticQuery(graphql`
    {
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const Testimonials = [
    {
      image: hansemerkur_green,
      name: "Katrin Menzel",
      position: "Senior Online Marketing Manager at HanseMerkur",
      text: "Implemented using Formcentric, the Insurance Finder is an impressive tool that offers our customers an easy way to find exactly the information that they are looking for. The options it gives us to develop the website in-house also strengthens the presence of HanseMerkur online — both today and for the future.",
      linkTo: "hansemerkur",
    },
    {
      image: bgw_green,
      name: "Oliver Willhöft",
      position: "Head of Software Technologies at BGW",
      text: "This stylish, contemporary design really makes the website shine. And I’m certain that the new technological approach and automation options are going to be a real help in the future, as we strive to become more responsive and more flexible.",
      linkTo: "bgw",
    },
    {
      image: haspa_green,
      name: "Peter Hußmann",
      position: "IT and Staffing Unit at Haspa",
      text: "From design or new functionality to maintenance of our content management system—with Monday Consulting we have a trusted partner who speaks our language, implements our requirements exactly as needed, and takes an innovative and creative approach to achieving optimum solutions.",
      linkTo: "haspa",
    },
  ];

  return (
    <Layout lang="en" langPath="nav.services.link">
      <Seo
        title="Our services – tailored solutions for your web presence"
        description="With a focus on accessibility and personalization, we optimize your digital presence and make your user experience unique and future-proof."
        keywords="User Experience, Implementation Partner, CMS Implementation, Content Management System, CMS, CoreMedia, Firstspirit, Crownpeak, Consulting, Software Development, Netlify, Dynamic Yield, Formcentric, IAAP, Accessibility, Personalization"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>

      <div className="container mt-4 mb-4 mb-md-4">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">Services</h1>
            <h2 className="text-size-2 text-size-md-1">
              Individual solutions for individual challenges
            </h2>
            <p>
              We offer you first-class digital solutions and a collaborative
              partnership that meets your requirements.
              <br />
              With our experienced team and state-of-the-art technologies, we
              not only ensure outstanding results, but also the ability to adapt
              quickly to new requirements – for your long-term and sustainable
              success.
            </p>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Specialization for your success"
        teaser="With our many years of expertise and selected partnerships, we are perfectly prepared to meet your requirements."
      />

      <div className="container">
        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-dots">
            <img
              className="w-100"
              src={doodle_path}
              alt="Breaking down digital barriers"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Inclusive digital world
            </h2>
            <h3>Breaking down digital barriers</h3>
            <p>
              We promote an inclusive digital world to make it usable for all
              people, regardless of individual limitations. Our team supports
              you in making your online presence inclusive and meeting future
              standards at an early stage. Be future-proof today and prepared
              for the EAA (European Accessibility Act).
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-circle">
            <img
              className="w-100"
              src={doodle_directions}
              alt="We accelerate your response time"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Targeted delivery of content
            </h2>
            <h3>Personalized experiences on the web</h3>
            <p>
              A personalized user experience increases sales, minimizes costs
              and promotes customer loyalty through tailored experiences based
              on individual needs – the ideal strategy for effective customer
              engagement and long-term customer relationships.
            </p>
          </div>
        </div>

        <div className="row mb-5 reveal">
          <div className="col-12 mb-3 mb-md-0 col-md-5 bg-size-contain bg-shape-vertigo">
            <img
              className="w-100"
              src={doodle_growIdeas}
              alt="Taking forward the digital transformation"
            />
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Taking forward the digital transformation
            </h2>
            <h3>Digitization of processes</h3>
            <p>
              Simply digitize analogue processes: Accelerate business processes
              with user-friendly online forms, automate services and generate
              leads – securely, GDPR-compliant and certified in accordance with
              ISO 27001. Take advantage of the Formcentric form manager for
              efficient and secure work.
            </p>
          </div>
        </div>
      </div>

      <ServicesTeaser
        title="Discover our possibilities"
        text={
          "With our expertise in strategy and conception, we work together to create high-quality solutions that meet tomorrow's standards today.\nWe provide comprehensive services, encompassing the selection of the most suitable content management system, unique design and creation, and optimization of front-end, back-end, and infrastructure, all from a single source.\n\nWe guide you through the entire process and provide first-class customer care even after the project has been completed."
        }
        ctaLink="/en/services/serviceportfolio"
        ctaText="Our portfolio of services"
      />

      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="testimonials"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="mb-3">
                These companies have moved to Monday Consulting
              </h2>
              <p>
                Companies in a wide range of industries use our tailor-made and
                future-proof solutions to secure success in the market by
                optimizing internal processes and improving their customer
                dialog. No two cases are the same – a fact of which we are
                particularly proud.
              </p>
              <Link className="btn" to="/en/references">
                Our case studies
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-2 align-items-center justify-content-center">
            <div className="p-2 flex-grow-0 mb-2">
              <img src={eppendorf} alt="Eppendorf Group" height="30px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={dak_gesundheit} alt="GDV" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={cosmos} alt="CosmosDirekt" height="70px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={tk} alt="Techniker Krankenkasse" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={swp} alt="Stadtwerke Potsdam" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={generali} alt="Generali" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="testimonials"
        className="container vertical-offset mb-5 mb-md-6 px-0 px-md-3"
        data-offset="50%"
      >
        <div className="swiper-container reveal">
          <div className="swiper-wrapper">
            {Testimonials.map((item, i) => (
              <Testimonial key={i} {...item} />
            ))}
          </div>
          <div className="swiper-pagination d-xl-none"></div>
        </div>
      </div>

      <ContactPerson
        headline="How can we help you today?"
        text="After browsing our impressive portfolio of services, why not contact us to find out more? Advice and assistance is just a phone call away."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Services;
